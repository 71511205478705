<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <h6 class="card-title text-center mt-5" v-if="!filtroValido">
        <span class="card-label">Para ver o histórico do talhão preencha os campos acima.</span>
      </h6>

      <h6 class="card-title text-center" v-if="loading">
        <v-progress-circular
            size="50"
            width="2"
            color="primary"
            indeterminate
            class="mr-2"
        />
        <span class="card-label">Carregando retiradas</span>
      </h6>

      <h6 class="card-title text-center" v-if="filtroValido && !loading && ciclos.length === 0">
        <span class="card-label">Nenhuma retirada foi encontrada.</span>
      </h6>
      <div class="pb-0 pt-3" v-if="filtroValido && !loading && ciclos.length > 0">
        <h3 class="card-title">
          <span class="card-label font-weight-bold text-dark">Retiradas</span>
        </h3>
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center table-head-bg table-borderless table-desktop">
            <thead>
            <tr>
              <th class="pl-5">início</th>
              <th>término</th>
              <th>área liberada</th>
              <th>área colhida</th>
              <th>área acerto</th>
              <th>aréa movimento</th>
              <th>colhido</th>
              <th>perda</th>
              <th>ações</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(ciclo, i) in ciclos">
              <tr :key="i">
                <td class="pl-5">
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.data_inicio }}</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.data_final }}</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.area_liberada_talhao }} m²</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.colhido }} m²</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.acerto }} m²</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.movimento }} m²</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.porcentagem_colhido }} %</span>
                </td>
                <td>
                      <span
                          class="text-dark-75 font-weight-medium d-block font-size-lg"
                      >{{ ciclo.porcentagem_perda }} %</span>
                </td>
                <td>
                  <a
                      class="btn font-weight-medium primary"
                      @click="verCiclo(ciclo.id)"
                  >Ver Ciclo {{ ciclo.numero_ciclo }}</a>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <table class="table table-head-custom table-vertical-center table-head-bg table-borderless table-mobile">
            <template v-for="(ciclo, i) in ciclos">
              <tr :key="i">
                <th>INÍCIO</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.data_inicio }}</span>
                </td>
              </tr>
              <tr :key="i">
                <th>TÉRMINO</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.data_final }}</span>
                </td>
              </tr>
              <tr :key="i">
                <th>ÁREA LIBERADA</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.area_liberada_talhao }} m²</span>
                </td>
              </tr>
              <tr :key="i">
                <th>ÁREA COLHIDA</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.colhido }} m²</span>
                </td>
              </tr>
              <tr :key="i">
                <th>COLHIDO</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.porcentagem_colhido }} %</span>
                </td>
              </tr>
              <tr :key="i">
                <th>PERDA</th>
                <td>
                  <span class="text-dark-75 font-weight-medium d-block font-size-lg">{{ ciclo.porcentagem_perda }} %</span>
                </td>
              </tr>
              <tr :key="i">
                <th>AÇÕES</th>
                <td>
                  <a class="btn primary  font-size-lg" @click="verCiclo(ciclo.id)">Ver Ciclo {{ ciclo.numero_ciclo }}</a>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { HistoricoTalhaoApi } from '@/core/api/historico-talhao.api'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    liberacao: {
      type: String,
      default: '',
    },
    filtro: {
      type: Object,
      default: () => ({
        empresa: null,
        producao: null,
        talhao: null,
        ano: (new Date()).getFullYear(),
      }),
    },
  },
  data: () => {
    return {
      loading: false,
      ciclos: [],
    }
  },
  computed: {
    filtroValido () {
      return this.filtro.talhao
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  mounted () {
    this.buscaCiclos()
  },
  watch: {
    filtro () {
      this.buscaCiclos()
    },
  },
  methods: {
    buscaCiclos () {
      this.model = ''

      if (!this.filtroValido) {
        return
      }

      this.loading = true

      HistoricoTalhaoApi
        .getCiclos(this.filtro.talhao, this.filtro.ano)
        .then((response) => {
          this.ciclos = this.formataDataValues(response.data)
          this.loading = false
          this.atualizaCiclo()
        })
        .catch(() => this.loading = false)
    },
    verCiclo (liberacaoColheitaId) {
      this.liberacao = liberacaoColheitaId
      this.atualizaCiclo()
    },
    atualizaCiclo () {
      this.ciclos
        .filter((item) => parseInt(item.id) === parseInt(this.liberacao))
        .map((item) => {
          this.model = {
            id: item.id,
            area_liberada: item.area_liberada,
            ciclo: item.numero_ciclo,
          }

          return item
        })
    },
    formataDataValues (values) {
      const $stringFormat = this.$stringFormat
      return values.map(function (item) {
        return Object.assign(item, {
          area_liberada_talhao: $stringFormat.formatNumber(item.area_liberada, 0, 0),
          area_talhao: $stringFormat.formatNumber(item.area_talhao, 0, 0),
          colhido: $stringFormat.formatNumber(item.colhido, 0, 0),
          acerto: $stringFormat.formatNumber(item.acerto, 0, 0),
          movimento: $stringFormat.formatNumber(item.movimento, 0, 0),
          porcentagem_colhido: $stringFormat.formatNumber(item.porcentagem_colhido),
          porcentagem_perda: $stringFormat.formatNumber(item.porcentagem_perda),
          data_inicio: $stringFormat.formatDate(item.data_inicial),
          data_final: $stringFormat.formatDate(item.data_finalizacao),
        })
      })
    },
  },
}
</script>

<style scoped>

.bg-light-warning {
  background-color: #c2c2c2 !important;
}
.text-warning {
  color: #4f4f4f !important;
}
.svg-icon.svg-icon-warning svg g [fill] {
  fill: #4f4f4f !important;
}

.bg-light-success {
  background-color: #aff28b !important;
}
.text-success {
  color: #308a00 !important;
}
.svg-icon.svg-icon-success svg g [fill] {
  fill: #308a00 !important;
}

.btn.primary {
  color: #fff !important;
}

.btn.pdf {
  background-color: #a93413;
  color: #fff;
}

.btn.excel {
  background-color: #3c6e43;
  color: #fff;
}

.timeline.timeline-6:before {
  left: 91px;
}

.timeline.timeline-6 .timeline-item .timeline-label {
  width: 90px;
}

.atividades-realizadas {
  overflow-y: auto;
}

.table-mobile {
  display: none;
}

.produto-card {
  height: 32vh;
}
.v-application .rounded-xl {
  height: auto;
}

@media(min-width: 767px) {
  .atividades-scroll {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .position-lg-absolute {
    position: absolute !important;
  }
}

@media (max-width: 600px) {
  .v-data-table {
    flex-direction: column;
    margin: 0 !important;
  }

  .v-data-table .col {
    padding: 0;
  }

  .aplicacoes-atividades-produtos {
    flex-direction: column;
  }

  .aplicacoes-atividades-produtos .col-sm-6 {
    width: 50%;
  }

  .produto-card {
    height: 25vh;
  }
}

@media (max-width: 750px) {
  .table-desktop {
    display: none;
  }

  .table-mobile {
    display: flex;
    flex-direction: column;
  }

  .table-mobile tr {
    justify-content: space-between;
    display: flex;
  }

  .produto-card {
    height: 28vh;
  }
}

@media (max-width: 960px) {
  .v-data-table .col {
    padding: 0;
  }
}

@media (max-width: 1263px) {
  .v-data-table .col {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
