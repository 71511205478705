<template>
  <FiltroMobile :has-change="change" @search="seachFilter">
    <v-col class="col-12 col-md-3">
      <EmpresasSelect v-model="empresa" obrigatorio @change="change = true"></EmpresasSelect>
    </v-col>
    <v-col class="col-12 col-md-3">
      <ProducoesSelect v-model="producao" :empresa="empresa" obrigatorio :disabled="!empresa" @change="change = true"></ProducoesSelect>
    </v-col>
    <v-col class="col-12 col-md-2">
      <TalhoesSelect v-model="talhao" :empresa="empresa" obrigatorio :producao="producao" :disabled="!producao" @change="change = true"></TalhoesSelect>
    </v-col>
    <v-col class="col-12 col-md-2">
      <v-text-field
          dense
          outlined
          label="Ano"
          @keydown="$stringFormat.validarNumero"
          :maxlength = "4"
          v-model="ano"
          @change="change = true"
      />
    </v-col>
  </FiltroMobile>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: {
        empresa: null,
        producao: null,
        talhao: null,
        ano: (new Date()).getFullYear(),
      },
    },
  },
  data: () => {
    return {
      change: false,
      empresa: null,
      producao: null,
      talhao: null,
      ano: null,
      anopicker: false,
    }
  },
  mounted () {
    this.empresa = this.value.empresa
    this.producao = this.value.producao
    this.talhao = this.value.talhao
    this.ano = this.value.ano
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      this.model = {
        empresa: this.empresa,
        producao: this.producao,
        talhao: this.talhao,
        ano: this.ano,
      }
    },
  },
}
</script>

<style scoped>

</style>