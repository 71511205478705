<template>
  <div>
    <FiltroHistoricoTalhaoCompoent v-model="filtro"></FiltroHistoricoTalhaoCompoent>
    <RetiradasPorCiclo v-model="ciclo" :liberacao="liberacao" :filtro="filtro"></RetiradasPorCiclo>
    <AplicacaoProduto :talhao="filtro.talhao" :ciclo="ciclo"></AplicacaoProduto>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import FiltroHistoricoTalhaoCompoent from './components/FilterHistoricoTalhaoComponent'
import RetiradasPorCiclo from './components/RetiradasPorCiclo'
import AplicacaoProduto from './components/AplicacaoProduto'

export default {
  components: { FiltroHistoricoTalhaoCompoent, RetiradasPorCiclo, AplicacaoProduto },
  data: (instance) => {
    return {
      filtro: {
        empresa: instance.$route.query.empresa ? parseInt(instance.$route.query.empresa) : null,
        producao: instance.$route.query.producao ? parseInt(instance.$route.query.producao) : null,
        talhao: instance.$route.query.talhao ? parseInt(instance.$route.query.talhao) : null,
        ano: instance.$route.query.ano ? parseInt(instance.$route.query.ano) : (new Date()).getFullYear(),
      },
      liberacao: instance.$route.query.liberacao ? parseInt(instance.$route.query.liberacao) : '',
      ciclo: null,
    }
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Histórico do Talhão' },
    ])
  },
}
</script>
<style>

.bg-light-warning {
  background-color: #FFF4DE !important;
}
.text-warning {
  color: #FFA800 !important;
}
.svg-warning svg g [fill] {
  fill: #FFA800 !important;
}

.bg-light-primary {
  background-color: #E1F0FF !important;
}
.text-primary {
  color: #3699FF !important;
}
.svg-primary svg g [fill] {
  fill: #3699FF !important;
}

.bg-light-default {
  background-color: #c2c2c2 !important;
}
.text-default {
  color: #4f4f4f !important;
}
.svg-default svg g [fill] {
  fill: #4f4f4f !important;
}

.bg-light-success {
  background-color: #aff28b !important;
}
.text-success {
  color: #308a00 !important;
}
.svg-success svg g [fill] {
  fill: #308a00 !important;
}

.bg-light-danger {
  background-color: #FFE2E5 !important;
}
.text-danger {
  color: #F64E60 !important;
}
.svg-danger svg g [fill] {
  fill: #F64E60 !important;
}
</style>
