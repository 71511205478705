import ApiService from '../services/api.service'
import { stringFormat } from '../functions/string-format'

export const HistoricoTalhaoApi = {
  getCiclos (talhao, ano) {
    if (ano) {
      return ApiService.get(`/historico-talhao/ciclos/${talhao}/${ano}`).then((res) => res.data)
    }

    return ApiService.get(`/historico-talhao/ciclos/${talhao}`).then((res) => res.data)
  },
  getAtividades (talhao, intervalo) {
    const params = Object.assign(intervalo, {
      talhao_id: talhao,
    })
    return ApiService.get(`/historico-talhao/ciclo/${talhao}`, `?${stringFormat.queryParamsRecursive(params)}`).then((res) => res.data)
  },
}
