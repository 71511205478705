<template>
  <v-card class="mb-5" v-if="this.talhao">
    <v-card-text class="pt-1">
      <h6 class="card-title text-center mt-5" v-if="!this.ciclo">
        <span class="card-label">Para ver os produtos aplicados, selecione um ciclo.</span>
      </h6>

      <div class="pt-3" v-if="this.ciclo && !error">
        <v-row>
          <v-col>

            <v-badge v-for="categoria in categorias" :value="categoriaFiltrada === categoria.codigo" dot overlap color="green" :key="'categoria-' + categoria.codigo">
              <v-chip :class="categoria.estilo.cor + ' chip--custom'" @click="selectCategoria(categoria.codigo)">
                <span :class="categoria.estilo.texto">{{ categoria.nome }}</span>
              </v-chip>
            </v-badge>
          </v-col>
        </v-row>
        <v-row class="aplicacoes-atividades-produtos">
          <v-col lg="8" md="8" sm="12">
            <h3 class="card-title">
              <span class="card-label font-weight-bold text-dark">Aplicação de Produtos</span>
            </h3>
            <v-row>
              <div
                  class="col-lg-4 col-md-5 col-sm-7"
                  v-for="(produto, i) in aplicacoes"
                  :key="i"
              >
                <v-badge class="w-100" :value="produtoFiltrado === produto.id" dot overlap color="green">
                  <div class="p-6 rounded-xl produto-card" :class="produto.estilo.cor" @click="selectProduto(produto.id)">
                      <span class="svg-icon svg-icon-3x d-block my-2" :class="produto.estilo.icone">
                        <inline-svg src="/media/svg/icons/Shopping/Box3.svg" />
                      </span>
                    <span class="font-size-h4 font-weight-medium d-block" :class="produto.estilo.texto">
                        {{ produto.quantidade }}
                        {{ produto.unidade }}
                      </span>
                    <span class="font-size-h6 font-weight-medium d-block" :class="produto.estilo.texto">
                        {{ produto.produto }}
                      </span>
                    <span class="font-size-p font-weight-medium d-block" :class="produto.estilo.texto">
                        {{ produto.dosagem }}
                        {{ produto.unidade }}/ha
                      </span>
                  </div>
                </v-badge>
              </div>
            </v-row>
          </v-col>
          <v-col lg="4" md="4" sm="12" class="col-minima">
            <div class="position-lg-absolute atividades-scroll d-flex flex-column">
              <h3 class="card-title">
                <span class="card-label font-weight-bold text-dark">Linha do Tempo de aplicação de produtos</span>
              </h3>
              <div class="atividades-realizadas">
                <div class="timeline timeline-6">
                  <template v-for="(atividade, i) in timeline">
                    <div class="timeline-item align-items-start" :key="i">
                      <div class="timeline-label font-weight-bold text-dark-75 font-size-lg">
                        {{ atividade.data }}
                      </div>
                      <div class="timeline-badge">
                        <i class="icon-xl fa fa-genderless" :class="atividade.tipo"/>
                      </div>
                      <div class="font-weight-medium font-size-lg timeline-content text-muted pl-3">
                        <br>
                        <span class="text-dark-75" v-html="atividade.produto" />
                        <br>
                        <span class="text-dark-75" v-html="atividade.quantidade + ' ' + atividade.unidade" />
                        <br>
                        <span class="text-dark-75">Dosagem: {{ atividade.dosagem }}
                          {{ atividade.unidade }}/ha
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="d-flex justify-content-center align-items-center my-5 pb-5">
          <v-progress-circular
              v-if="loading"
              size="50"
              width="5"
              color="primary"
              indeterminate
          />
          <custom-alert
              v-if="error"
              :text="errorMessage"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { HistoricoTalhaoApi } from '@/core/api/historico-talhao.api'

export default {
  props: {
    talhao: {
      type: String,
      default: '',
    },
    ciclo: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      atividades: [],
      error: false,
      errorMessage: '',
      categoriaFiltrada: null,
      produtoFiltrado: null,
    }
  },
  computed: {
    fatorDivisao () {
      return this.ciclo.area_liberada / 10000
    },
    categorias () {
      return this.atividades.map((atividade) => {
        return {
          nome: atividade.categoria_nome,
          codigo: atividade.categoria_codigo,
          subcategoria: this.subcategoria(atividade.categoria_codigo),
          estilo: this.estilo(atividade.categoria_codigo),
        }
      }).filter((categoria, index, categorias) => categorias.findIndex((cBusca) => cBusca.codigo === categoria.codigo) === index)
        .sort((categoria1, categoria2) => (categoria1.codigo < categoria2.codigo) ? 1 : -1)
        .sort((categoria1, categoria2) => (categoria1.subcategoria > categoria2.subcategoria) ? 1 : -1)
    },
    timeline () {
      return this.atividades
        .filter((produto) => this.categoriaFiltrada === null || produto.categoria_codigo === this.categoriaFiltrada)
        .filter((produto) => this.produtoFiltrado === null || produto.produto_id === this.produtoFiltrado)
        .map((produto) => {
          return {
            data: this.$stringFormat.formatDate(produto.data_realizacao),
            tipo: this.estilo(produto.categoria_codigo).texto,
            produto: produto.descricao,
            quantidade: this.$stringFormat.formatNumber(produto.dosagem * this.fatorDivisao, 2, 3),
            dosagem: this.$stringFormat.formatNumber(produto.dosagem * this.fatorDivisao / this.fatorDivisao, 2, 3),
            unidade: produto.unidade_medida,
          }
        })
    },
    aplicacoes () {
      const aplicacoes = []

      this.atividades
        .filter((produto) => this.categoriaFiltrada === null || produto.categoria_codigo === this.categoriaFiltrada)
        .map((produto) => {
          return {
            id: produto.produto_id,
            estilo: this.estilo(produto.categoria_codigo),
            produto: produto.descricao,
            quantidade: produto.dosagem * this.fatorDivisao,
            unidade: produto.unidade_medida,
          }
        })
        .map((item) => {
          const indexitem = aplicacoes.findIndex((aplicacao) => aplicacao.id === item.id)

          if (indexitem >= 0) {
            aplicacoes[indexitem].quantidade += item.quantidade
            return
          }

          aplicacoes.push(item)
        })

      return aplicacoes.map((item) => Object.assign(item, {
        quantidade: this.$stringFormat.formatNumber(item.quantidade, 2, 3),
        dosagem: this.$stringFormat.formatNumber(item.quantidade / this.fatorDivisao, 2, 3),
      }))
    },
  },
  watch: {
    ciclo () {
      if (!this.talhao || !this.ciclo) {
        return
      }

      this.error = false
      this.atividades = []
      this.loading = true
      this.categoriaFiltrada = null
      this.produtoFiltrado = null

      HistoricoTalhaoApi
        .getAtividades(this.talhao, this.ciclo)
        .then((response) => {
          this.atividades = response.data
          this.loading = false

          if (this.atividades.length === 0) {
            this.error = true
            this.errorMessage = 'Não foram encontradas atividades nesse ciclo'
          }
        })
        .catch(() => {
          this.loading = false
          this.error = true
          this.errorMessage = 'Não foram encontradas atividades nesse ciclo'
        })
    },
  },
  methods: {
    selectCategoria (categoria) {
      this.produtoFiltrado = null

      if (this.categoriaFiltrada === categoria) {
        this.categoriaFiltrada = null
        return
      }

      this.categoriaFiltrada = categoria
    },
    selectProduto (produto) {
      if (this.produtoFiltrado === produto) {
        this.produtoFiltrado = null
        return
      }

      this.produtoFiltrado = produto
    },
    subcategoria (categoria) {
      return ({
        '0101': 1,
        '0103': 2,
        '0104': 2,
        '0105': 5,
        '0106': 5,
        '0107': 2,
        '0108': 3,
        '0109': 4,
        '0110': 4,
        '0111': 4,
      })[categoria]
    },
    estilo (categoria) {
      if (categoria === '0101') {
        return {
          cor: 'bg-light-success',
          icone: 'svg-success',
          texto: 'text-success',
        }
      }

      if (categoria === '0103') {
        return {
          cor: 'bg-light-danger',
          icone: 'svg-danger darken-1',
          texto: 'text-danger',
        }
      }

      if (categoria === '0104') {
        return {
          cor: 'bg-light-danger darken-2',
          icone: 'svg-danger',
          texto: 'text-danger',
        }
      }

      if (categoria === '0105') {
        return {
          cor: 'bg-light-default darken-1',
          icone: 'svg-default',
          texto: 'text-default',
        }
      }

      if (categoria === '0106') {
        return {
          cor: 'bg-light-default darken-2',
          icone: 'svg-default',
          texto: 'text-default',
        }
      }

      if (categoria === '0107') {
        return {
          cor: 'bg-light-danger darken-3',
          icone: 'svg-danger',
          texto: 'text-danger',
        }
      }

      if (categoria === '0108') {
        return {
          cor: 'bg-light-primary darken-3',
          icone: 'svg-primary',
          texto: 'text-primary',
        }
      }

      if (categoria === '0109') {
        return {
          cor: 'bg-light-warning darken-1',
          icone: 'svg-warning',
          texto: 'text-warning',
        }
      }

      if (categoria === '0110') {
        return {
          cor: 'bg-light-warning darken-2',
          icone: 'svg-warning',
          texto: 'text-warning',
        }
      }

      if (categoria === '0111') {
        return {
          cor: 'bg-light-warning darken-3',
          icone: 'svg-warning',
          texto: 'text-warning',
        }
      }

      if (categoria === '0112') {
        return {
          cor: 'bg-light-primary darken-1',
          icone: 'svg-primary',
          texto: 'text-primary',
        }
      }

      return {
        cor: 'bg-light-default',
        icone: 'svg-default',
        texto: 'text-default',
      }
    },
  },
}
</script>

<style scoped>
.chip--custom {
  font-size: 1rem !important;
  margin-right: 0.5rem;
  max-width: 150px;
}
.chip--custom span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.darken-1 {
  filter: contrast(95%) saturate(150%);
}
.darken-2 {
  filter: contrast(90%) saturate(200%);
}
.darken-3 {
  filter: contrast(80%) saturate(300%);
}

.btn.primary {
  color: #fff !important;
}

.btn.pdf {
  background-color: #a93413;
  color: #fff;
}

.btn.excel {
  background-color: #3c6e43;
  color: #fff;
}

.timeline.timeline-6:before {
  left: 91px;
}

.timeline.timeline-6 .timeline-item .timeline-label {
  width: 90px;
}

.atividades-realizadas {
  overflow-y: auto;
}

.table-mobile {
  display: none;
}

.produto-card {
  height: 32vh;
}
.v-application .rounded-xl {
  height: auto;
}

@media(min-width: 767px) {
  .col-minima {
    min-height: 50vh;
  }
  .atividades-scroll {
    width: calc(100% - 24px);
    top: 12px;
    bottom: 12px;
    left: 12px;
    right: 12px;
  }

  .position-lg-absolute {
    position: absolute !important;
  }
}

@media (max-width: 600px) {
  .v-data-table {
    flex-direction: column;
    margin: 0 !important;
  }

  .v-data-table .col {
    padding: 0;
  }

  .aplicacoes-atividades-produtos {
    flex-direction: column;
  }

  .aplicacoes-atividades-produtos .col-sm-6 {
    width: 50%;
  }

  .produto-card {
    height: 25vh;
  }
}

</style>
